/**
 * Module to help with determining platform of the client
 */

function isApple(options) {
  const {mac, ipad, iphone} = options;

  if (mac && window.navigator.platform.startsWith("Mac"))
    return true;

  if (ipad && window.navigator.platform.startsWith("iPad"))
    return true;
    
  if (iphone && window.navigator.platform.startsWith("iPhone"))
    return true;

  return false;
}

function isWindows() {
  return window.navigator.platform.startsWith("Win");
}

function isOther() {
  /**
   * NOTE this could include andriod, linux (mobile or desktop), chrome os, etc.
   */

  return !isWindows() && !isApple({mac: true, ipad: true, iphone: true});
}

export default {
  isApple,
  isWindows,
  isOther
};