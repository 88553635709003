/**
 * A class that controls the behavior of a key-navigated list
 */

export default class KeyNavigatedList {
  constructor(root, scrollAreaRoot, {onSelect = null, onUnselect = null, onChoose}) {
    this.root = root;
    this.scrollAreaRoot = scrollAreaRoot;
    this.onSelect = onSelect;
    this.onUnselect = onUnselect;
    this.onChoose = onChoose;

    let options = {
      root: this.scrollAreaRoot,
      threshold: 0.5
    };

    this.arrowUpObserver = new IntersectionObserver(entries => {
      const entry = entries[0];

      /**
       * if the element is out of view we want to set the scrolling
       * element's scroll top to the 'real' top of the target element
       * - the hight of the scrolling element
       */

      if (!entry.isIntersecting) {
        const scrollBoundingRect = this
          .scrollAreaRoot
          .getBoundingClientRect();

        const topWithScrollOffset = entry.boundingClientRect.top + this.scrollAreaRoot.scrollTop;
        const newScroll = topWithScrollOffset - scrollBoundingRect.top;

        this.setScrollAreaScrollTop(newScroll);
      }
    }, options);

    this.arrowDownObserver = new IntersectionObserver(entries => {
      const entry = entries[0];

      if (!entry.isIntersecting) {
        const scrollBoundingRect = this
          .scrollAreaRoot
          .getBoundingClientRect();

        const bottomWithScrollOffset = entry.boundingClientRect.bottom + this.scrollAreaRoot.scrollTop;
        
        // NOTE scroll past 8 px to give effect of 'padding' so highlight isn't directly against
        // bottom of palette
        const newScroll = bottomWithScrollOffset - scrollBoundingRect.bottom + 8;

        this.setScrollAreaScrollTop(newScroll);
      }
    }, options);
  }

  get results() {
    return this.root?.querySelector("ul#results");
  }

  get selected() {
    return this.results?.querySelector("li[aria-selected=true]");
  }

  get first() {
    const e = this.getFirstSelectableChild(this.results);
    return e;
  }

  get next() {
    if (this.selected) {
      return this.getNextSelectableSibling(this.selected);
    }

    return this.first;
  }

  get previous() {
    if (this.selected) {
      return this.getPreviousSelectableSibling(this.selected);
    }

    return this.first;
  }

  handleKeyDown(event) {
    const key = event.key;

    switch (key) {
    case "ArrowUp":
      if (this.results) {
        const prev = this.previous;

        if (!prev) {
          // if no previous element, we're most likely at the top,
          // so make sure scroll is 0

          this.scrollAreaRoot.scrollTop = 0;
        }

        if (this.selected && prev) {
          this.select(prev);
          this.arrowUpObserver.observe(prev);
        }
      }  

      event.preventDefault();

      break;
    case "ArrowDown":
      if (this.results) {
        const next = this.next;

        if (this.selected) {
          if (next) {
            this.select(next);
            this.arrowDownObserver.observe(next);
          }

        } else {
          // select first
          this.select(this.first);
          this.arrowDownObserver.observe(this.first);
        }
      }

      event.preventDefault();

      break;
    case "Enter":
      if (this.selected && this.onChoose instanceof Function) {
        this.onChoose(this.selected);
        event.preventDefault();
      }

      break;
    }
  }

  destroy() {
    this.arrowDownObserver.disconnect();
    this.arrowUpObserver.disconnect();
  }

  select(element) {
    if (this.selected) {
      if (this.onUnselect instanceof Function)
        this.onUnselect(this.selected);

      this.selected.removeAttribute("aria-selected");
    }

    element.setAttribute("aria-selected", true);

    if (this.onSelect instanceof Function)
      this.onSelect(element);
  }

  getNextSelectableSibling(element) {
    const next = element.nextElementSibling;

    if (next && next.getAttribute("data-selectable") == "false") {
      return this.getNextSelectableSibling(next);
    }

    return next;
  }

  getPreviousSelectableSibling(element) {
    const prev = element.previousElementSibling;

    if (prev && prev.getAttribute("data-selectable") == "false") {
      return this.getPreviousSelectableSibling(prev);
    }

    return prev;
  }

  getFirstSelectableChild(element) {
    const first = element?.firstElementChild;

    if (first && first.getAttribute("data-selectable") == "false") {
      return this.getNextSelectableSibling(first);
    }

    return first;
  }

  setScrollAreaScrollTop(value) {
    this.scrollAreaRoot.scrollTop = value;

    // disconnect observers after scrolled to prevent issues with manual scrolling
    // via mouse input
    this.arrowDownObserver.disconnect();
    this.arrowUpObserver.disconnect();
  }
}
