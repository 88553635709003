/**
 * Module that helps update notification records
 */

import Rails from "@rails/ujs";

export type CreateResponse = {
  success: boolean;
  data?: {url: string};
};

export type CreateParams = {
  plan_id: number,
  price_name: string,
  return_url: string,
  source: string
};

export async function create({plan_id, return_url, price_name, source}: CreateParams): Promise<CreateResponse> {
  const url = "/checkouts";

  const payload = {
    checkout: {
      plan_id,
      price_name,
      return_url,
      source
    }
  };

  const res = await fetch(url, {
    method: "POST",
    headers: {
      "X-CSRF-Token": Rails.csrfToken(),
      "Content-Type": "application/json",
      "Accept": "application/json",
      "HTTP_USER_AGENT": "Turbo Native"
    },
    body: JSON.stringify(payload)
  });

  if (res.ok) {
    const data = await res.json();
    return {success: true, data: {url: data.url}};
  }

  throw new Error(`Unable to get checkout URL. Server response: ${res.status} '${await res.text()}'`);
}
