/**
 * Sets the hubspot tracking code on an element
 */

import {Controller} from "@hotwired/stimulus";
import * as Util from "../lib/util";

export default class extends Controller {
  static targets = ["value"];

  valueTargetConnected(element) {
    element.value = Util.getHubSpotTrackingCode();
  }
}
