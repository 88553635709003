/**
 * Module that helps update notification records
 */

import Rails from "@rails/ujs";

export type UpdateResponse = {
  success: boolean;
  data: {showBadge: boolean};
}

export async function update({id, readAt}: {id: number, readAt: boolean}): Promise<UpdateResponse> {
  const url = `/notifications/${id}`;

  const payload = {
    notification: {
      read_at: readAt
    }
  }

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      },
      body: JSON.stringify(payload)
    });

    if (response.status >= 300)
      throw new Error(`Server responded ${response.status}. Body: ${await response.text()}`);

    const body = await response.json();

    return {success: true, data: {showBadge: !!body.show_badge}};

  } catch (error) {
    throw new Error(`Api Error: ${error.message}`);
  }
}
