/**
 * A new, simpler modal controller. Use this one instead of modal_controller.
 */

import {Controller} from "@hotwired/stimulus";
import NewModal from "../lib/new_modal";

export default class extends Controller {
  static targets = ["button", "close", "closeAll"];

  connect() {
    document.addEventListener("click", event => {
      if (NewModal.shouldClose(event))
        NewModal.hide();
    });
  }

  buttonTargetConnected(button) {
    button.addEventListener("click", event => {
      const modalId = button.getAttribute("data-modal-id");
      const modalAction = button.getAttribute("data-modal-action");
      const resetFrames = button.getAttribute("data-reset-frames") == "true";
      const resetFramesToPath = button.getAttribute("data-reset-frames-to-path");

      if (modalId && modalAction == "show") {
        NewModal.show(modalId);
      } else if (modalId && modalAction == "hide") {
        NewModal.hide(modalId, {resetFrames: !!resetFrames, resetFramesToPath});
      }

      event.stopPropagation();
      event.preventDefault();
    });
  }

  closeTargetConnected() {
    /**
     * Some turbo stream responses include a div with this target that
     * forces closed the top open modals.
     */

    if (NewModal.isModalOpen()) {
      NewModal.hide(null, {resetFrames: true});
    }
  }

  closeAllTargetConnected() {
    /**
     * Some turbo stream responses include a div with this target that
     * forces closed the ALL open modals.
     */

    while (NewModal.isModalOpen()) {
      NewModal.hide();
    }
  }
}
