import { Controller } from "@hotwired/stimulus"
import {enter, leave} from "el-transition";

// Connects to data-controller="appearance"
export default class extends Controller {
    static targets = ["item"];

    itemTargetConnected(element) {
        enter(element).then(() => {
            leave(element)
        })
    }
}
