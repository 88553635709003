import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mobile", "closedIcon", "openIcon"];

  connect() {
    // Hide mobile menu by default
    if (this.hasMobileTarget) {
      this.mobileTarget.classList.toggle("hidden", true);
      this.data.set("mobileMenuDisplayed", "false");
    }
  }

  toggleMobileMenu() {
    var mobileMenuDisplayed = this.data.get("mobileMenuDisplayed") === "true";
    this.data.set("mobileMenuDisplayed", !mobileMenuDisplayed);
    this.mobileTarget.classList.toggle("hidden", mobileMenuDisplayed);
    this.closedIconTarget.classList.toggle("hidden", !mobileMenuDisplayed);
    this.openIconTarget.classList.toggle("hidden", mobileMenuDisplayed);
  }
}
