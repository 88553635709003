
const LOADING_HTML = `
  <div class="w-full flex justify-center align-center">
    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>    
  </div>
`;

const X_OUTLINE_HTML = `
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
  </svg>
`;

const COMMAND_PALETTE_TURBO_FRAME_ID = "command_palette";

const SHOW_COMMAND_PALETTE_ACTION_NAME = "application#showCommandPalette";

/**
 * The required length of a search string to trigger a type ahead submission
 */
const QUERY_TEXT_REQUIRED_LENGTH = 2;

export default {
  LOADING_HTML,
  X_OUTLINE_HTML,
  COMMAND_PALETTE_TURBO_FRAME_ID,
  SHOW_COMMAND_PALETTE_ACTION_NAME,
  QUERY_TEXT_REQUIRED_LENGTH
};