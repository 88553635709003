import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["marquee"];

  connect() {
    this.initializeMarquees();
  }

  initializeMarquees() {
    this.marqueeTargets.forEach(element => {
      this.setupMarquee(element);
      this.runMarquee(element);
    });
  }

  setupMarquee(element) {
    const gap = window.innerWidth > 768 ? 16 : 10;
    const firstThumbnail = element.children[0];
    element.dataset.moveDistance = (firstThumbnail.offsetWidth + gap).toString();
    element.dataset.accumulatedTranslation = "0";
    element.dataset.defaultFactor = element.dataset.factor || "0.025"; // Store the default factor
    element.style.willChange = "transform"; // Hint to browser for optimization
  }

  runMarquee(element) {
    let previousTimeStamp;
    let accumulatedTranslation = parseFloat(element.dataset.accumulatedTranslation);

    const restackAndCorrectPosition = () => {
      const firstThumbnail = element.children[0];
      const moveDistance = parseFloat(element.dataset.moveDistance);

      // Remove the first thumbnail and append it to the end
      element.appendChild(firstThumbnail);

      // Adjust accumulated translation to remove the moved distance
      accumulatedTranslation -= moveDistance;
      element.dataset.accumulatedTranslation = accumulatedTranslation.toString();

      // Remove the transition class to immediately apply the new position
      element.classList.remove("marquee-transition");
      element.style.transform = `translate3d(${-accumulatedTranslation}px, 0, 0)`;

      // Re-add the transition class for smooth scrolling after a frame
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.classList.add("marquee-transition");
        });
      });
    };

    const step = timestamp => {
      if (!previousTimeStamp) {
        previousTimeStamp = timestamp;
        requestAnimationFrame(step);
        return;
      }

      const elapsed = timestamp - previousTimeStamp;
      const factor = parseFloat(element.dataset.factor);
      accumulatedTranslation += elapsed * factor;

      // Apply the translation
      element.style.transform = `translate3d(${-accumulatedTranslation}px, 0, 0)`;

      // Check if the first thumbnail is completely out of view
      if (accumulatedTranslation >= parseFloat(element.dataset.moveDistance)) {
        restackAndCorrectPosition();
      }

      previousTimeStamp = timestamp;
      requestAnimationFrame(step);
    };

    requestAnimationFrame(step);
  }

  changeMarqueeFactor(event) {
    const element = event.currentTarget;
    const isMouseOver = event.type === "mouseover";
    const factor = isMouseOver ? 0.0125 : parseFloat(element.dataset.defaultFactor);
    element.dataset.factor = factor.toString();
  }
}
