import {Controller} from "@hotwired/stimulus";
import {enter, leave} from "el-transition";

export default class extends Controller {
  static targets = ["list", "subnav", "subtoggle", "button"];

  /**
   * lifecycle
   */
  connect() {
    this.data.set("dropdownOpen", "false");
    document.addEventListener("click", this.handleDocumentClick.bind(this));
  }

  listTargetConnected(target) {
    target.classList.toggle("hidden", true);
  }

  /**
   * actions
   */
  toggle() {
    let dropdownOpen = this.isOpen();
    let subnavOpen = this.isSubnavOpen();
    let tabindex = this.getTabIndex();

    if (dropdownOpen) {
      leave(this.listTarget)
      .then(() => {
        this.data.set("dropdownOpen", false);
        this.buttonTarget.setAttribute("aria-expanded", false);
        this.listTarget.setAttribute("aria-hidden", true);
        this.listTarget.setAttribute("tabindex", tabindex);
      });
    } else {
      enter(this.listTarget)
      .then(() => {
        this.data.set("dropdownOpen", true);
        this.buttonTarget.setAttribute("aria-expanded", true);
        this.listTarget.setAttribute("aria-hidden", false);
        this.listTarget.setAttribute("tabindex", tabindex);
      });
    }

    if (subnavOpen && dropdownOpen) {
      this.toggleSubnav();
    }
  }

  toggleSubnav() {
    let subnavOpen = this.isSubnavOpen();
    let tabindex = this.getTabIndex();

    this.data.set("subnavOpen", !subnavOpen);
    this.subnavTarget.classList.toggle("hidden", subnavOpen);
    this.subtoggleTarget.setAttribute("aria-expanded", !subnavOpen);
    this.subnavTarget.setAttribute("aria-hidden", subnavOpen);
    this.subnavTarget.setAttribute("tabindex", tabindex);
  }

  keyHide(e) {
    if (e.keyCode == 27 && this.isOpen()) {
      e.preventDefault();
      this.toggle();
    }
  }

  /**
   * helper methods
   */
  handleDocumentClick(e) {
    if (this.shouldCloseOnClick(e)) {
      this.toggle();
    }
  }

  isOpen() {
    return this.data.get("dropdownOpen") === "true";
  }

  isSubnavOpen() {
    return this.data.get("subnavOpen") === "true";
  }

  getTabIndex() {
    return this.isSubnavOpen() ? -1 : "";
  }

  shouldCloseOnClick(e) {
    if (this.hasButtonTarget) {
      const eventPath = e.composedPath();
      const pathHasButtonTarget = eventPath.includes(this.buttonTarget);
      const pathHasListTarget = eventPath.includes(this.listTarget);

      return this.isOpen() &&
          !pathHasButtonTarget &&
          !pathHasListTarget;
    } else {
      return false;
    }
  }
}
