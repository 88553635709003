/**
 * Functionality for the new modal system
 */

import {enter} from "el-transition";
import * as Util from "../lib/util";

function show(id) {
  const modal = document.getElementById(id);
  modal.classList.toggle("hidden", false);

  const panel = modal.querySelector("[data-modal-role=panel]");
  enter(panel);

  pushModalId(id);

  Util.disableScroll();
}

function hide(id = null, options = {}) {
  if (!id)
    id = popModalId();

  const modals = document.querySelectorAll(`#${id}`);

  if (modals) {
    modals.forEach(modal => {
      const resetFrames = modal.getAttribute("data-reset-frames");
      const resetFramesPath = modal.getAttribute("data-reset-frames-to-path");

      if (resetFrames) {
        const frames = modal.querySelectorAll("turbo-frame");

        frames.forEach(frame => {
          if (resetFramesPath) {
            frame.setAttribute("src", resetFramesPath);
          }

          frame.reload();
        });
      }
  
      modal.classList.toggle("hidden", true);
    });
  }

  popModalId({save: true});

  if (!isModalOpen()) {
    Util.enableScroll();
  }
}

function shouldClose(event) {
  if (!isModalOpen())
    return false;

  const openModalId = popModalId();

  if (openModalId) {
    const path = event.composedPath();
    let modal = document.getElementById(openModalId);
    const panel = modal.querySelector("[data-modal-role='panel']");

    if (!path.includes(panel))
      return true;
  }

  return false;
}

function isModalOpen() {
  /**
   * Returns true if there are any open modals
   */

  return document
    .body
    .getAttribute("data-new-open-modal-id")
    ?.trim()
    ?.length > 0;
}

function pushModalId(id) {
  const openModals = document
    .body
    .getAttribute("data-new-open-modal-id")
    .split(" ");
  
  openModals.push(id);

  document.body.setAttribute(
    "data-new-open-modal-id",
    openModals.join(" ")
  );
}

function popModalId(options = {save: false}) {
  if (!isModalOpen())
    return;

  const {save} = options;

  const poppedModalId = document
    .body
    .getAttribute("data-new-open-modal-id")
    .split(" ")
    .filter(value => value != "")
    .pop();

  if (save) {
    const value = document
      .body
      .getAttribute("data-new-open-modal-id")
      .split(" ")
      .filter(value => value != "");

    value.pop();

    document
      .body
      .setAttribute(
        "data-new-open-modal-id",
        value.join(" ")
      );
  }

  return poppedModalId;
}

export default {
  show,
  hide,
  isModalOpen,
  shouldClose
};
