import {Controller} from "@hotwired/stimulus";
import {enter, leave} from "el-transition";

export default class extends Controller {
  static targets = ["container"];

  static values = {
    autohide: Boolean
  }

  connect() {
    enter(this.containerTarget);

    if (this.autohideValue) {
      setTimeout(() => {
        leave(this.containerTarget);
      }, 5000);
    }
  }

  dismiss() {
    leave(this.containerTarget);
  }
}
