/**
 * Client-side behavior for the search command palette
 */

import {Controller} from "@hotwired/stimulus";
import {debounce} from "lodash";
import Constants from "../../lib/constants";
import Platform from "../../lib/platform";
import CommandPalette from "../../lib/command_palette";
import Interactions from "../../lib/interactions";
import KeyNavigatedList from "../../lib/key_navigated_list";

export default class extends Controller {
  static targets = [
    "container",
    "overlay",
    "palette",
    "form",
    "input",
    "submit",
    "results",
    "loading",
    "clear",
    "icon",
    "resultsScrollArea"
  ];

  /**
   * lifecycle
   */

  connect() {
    this.list = new KeyNavigatedList(
      this.resultsTarget,
      this.resultsScrollAreaTarget, {
        onSelect: this.selectResult.bind(this),
        onUnselect: this.unselectResult.bind(this),
        onChoose: this.chooseResult.bind(this)
      }
    );

    document.addEventListener("click", event => {
      const path = event.composedPath();
      const target = event.target;
      const targetAction = target.getAttribute("data-action") || "";

      const pathIncludesFrame = path.includes(this.paletteTarget) ||
        path.includes(document.querySelector("[data-nav--command-palette-target='container']"));

      const targetOpensCommandPalette = targetAction.includes(Constants.SHOW_COMMAND_PALETTE_ACTION_NAME);

      const targetIsSubmitForQuery = target.getAttribute("data-nav--command-palette-target") == "submit" &&
        target.tagName == "INPUT"; 

      const shown = this.shown();

      /**
       * Ensure clicked element is not in command palette, a 
       * button that opens the command palette, or a click on a
       * submit button for the query form
       */
      if (!pathIncludesFrame && !targetOpensCommandPalette && !targetIsSubmitForQuery && shown) {
        this.hide();
      }
    });

    document.addEventListener("turbo:before-cache", () => {
      /**
       * Need to hide overlay and palette on cache so the palette is not open if
       * the user navigates back in their browser.
       */

      this.overlayTarget.classList.toggle("hidden", true);
      this.containerTarget.classList.toggle("hidden", true);
    });
  }

  disconnect() {
    this.list?.destroy();
  }

  inputTargetConnected(element) {
    element.addEventListener("input", debounce(event => {
      const queryText = event.target.value;

      const shouldSubmitForm = !queryText ||
        queryText.length == 0 ||
        queryText.length >= Constants.QUERY_TEXT_REQUIRED_LENGTH;

      if (shouldSubmitForm) {
        this.performSubmit();

      } else if (queryText.length == 0) {
        // clear out results panel if we have no query text so outdated results don't linger
        this.resultsTarget.innerHTML = "";
      }

    }, 300));

    CommandPalette.focus();
  }

  formTargetConnected() {
    document.addEventListener("turbo:before-fetch-request", event => {
      if (event.target == this.formTarget) {
        // store the request URL so we can ensure that the most recently
        // requested frame is ultimately rendered
        const requestUrl = event.detail.url.href;
        this.mostRecentRequestUrl = requestUrl;
      }
    });
  }

  resultsTargetConnected() {
    document.addEventListener("turbo:frame-render", event => {
      if (event.target == this.resultsTarget)
        this.toggleLoadingIcon(false);
    });

    document.addEventListener("turbo:before-fetch-response", event => {
      if (event.target == this.formTarget) {
        // ensure that this frame is for the correct request
        const responseUrl = event.detail.fetchResponse.response.url;

        if (responseUrl != this.mostRecentRequestUrl) {
          event.preventDefault();
        }
      }
    });
  }

  resultsTargetDisconnected() {
    this.list?.destroy();
  }

  /**
   * actions 
   */

  ensureClearButtonDisplayState() {
    // NOTE Need to handle this in a change handler so that the
    // event is handle AFTER the form field updates (key down)
    // fires before form value changes)

    this.ensureClearButton();
  }

  handleKeyDown(event) {
    this.list.handleKeyDown(event);
  }

  handleDocumentKeydown(event) {
    switch (event.key) {
    case "Escape":

      if (this.shown()) {
        this.hide();
        event.preventDefault();
      }

      break;
    case "k":
      const shown = new CommandPalette.State().shown;
      let shouldToggleCommandPalette = false;
      
      if (Platform.isApple({mac: true})) {
        shouldToggleCommandPalette = event.metaKey;
      } else if (Platform.isWindows()) {
        shouldToggleCommandPalette = event.ctrlKey;
      } else {
        shouldToggleCommandPalette = event.ctrlKey;
      }

      if (shouldToggleCommandPalette && !shown) {
        Interactions.createButtonInteraction({
          source: "nav",
          location: "top_bar",
          detail: "command_palette_key_command"
        });

        this.show();
        event.preventDefault();

      } else if (shouldToggleCommandPalette && shown) {
        this.hide();
        event.preventDefault();
      }

      break;
    }
  }

  clearQuery() {
    this.inputTarget.value = "";
    this.toggleClearButton(false);
    this.performSubmit({refocus: false, showLoadingIcon: false});
  }

  /**
   * helpers
   */

  show() {
    CommandPalette.show();
  }

  hide() {
    CommandPalette.hide(this.containerTarget, this.overlayTarget);

    this.clearQuery();
  }

  selectResult(element) {
    element.classList.toggle("bg-concert-800", true);
    element.classList.toggle("text-concert-400", false);
    element.classList.toggle("text-concert-100", true);
  }

  unselectResult(element) {
    element.classList.toggle("bg-concert-800", false);
    element.classList.toggle("text-concert-400", true);
    element.classList.toggle("text-concert-100", false);
  }

  chooseResult(element) {
    const link = element.querySelector("a");

    if (link)
      link.click();
  }

  shown() {
    return this.element.getAttribute("data-shown") == "true";
  }

  performSubmit(options = {}) {
    const {refocus = true, showLoadingIcon = true} = options;

    this.submitTarget.removeAttribute("disabled");
    this.submitTarget.click();

    if (refocus)
      this.inputTarget.focus();
    
    if (showLoadingIcon)
      this.toggleLoadingIcon(true);
  }

  toggleClearButton(shown) {
    this
      .clearTarget
      .classList
      .toggle("hidden", !shown);
  }

  ensureClearButton() {
    /**
     * Shows or hides the clear button depending on whether or not
     * there is a query to clear
     */

    const hasQuery = this.inputTarget.value.length > 0;
    this.toggleClearButton(hasQuery);
  }

  toggleLoadingIcon(shown) {
    // search icon is only visible when loading icon is not
    this.toggleSearchIcon(!shown);

    this
      .loadingTarget
      .classList
      .toggle("hidden", !shown);
  }

  toggleSearchIcon(shown) {
    this
      .iconTarget
      .classList
      .toggle("hidden", !shown);
  }
}
