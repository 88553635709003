/**
 * Sets the facebook tracking codes on fields
 */

import { Controller } from "@hotwired/stimulus";
import * as Util from "../lib/util";

export default class extends Controller {
  static targets = ["fbp", "fbc"];

  fbpTargetConnected(element) {
    element.value = Util.getFacebookBrowserCookie();
  }

  fbcTargetConnected(element) {
    element.value = Util.getFacebookClickID();
  }
}
