import {Controller} from "@hotwired/stimulus";
import {enter, leave} from "el-transition";

export default class extends Controller {
  static targets = ["panel", "button", "input"];

  /**
   * lifecycle
   */

  connect() {
    this.buttonTargets.forEach(button => {
      if (button.getAttribute("data-default") == "true") {
        this.select({currentTarget: button});
      }
    });
  }

  /**
   * actions
   */

  select(event) {
    const label = event.currentTarget;
    const name = label.getAttribute("data-panel");
    const input = label.querySelector("input");
    input.checked = true;

    this.activatePanelsByName(name);
  }

  /**
   * helpers
   */

  get activePanels() {
    return this.panelTargets.filter(panel => !panel.classList.contains("hidden"));
  }

  getPanelsByName(name) {
    return this.panelTargets.filter(panel => panel.getAttribute("data-panel") == name);
  }

  activatePanelsByName(name) {
    const panels = this.getPanelsByName(name);
    const activePanels = this.activePanels;

    if (activePanels.length > 0) {
      activePanels.forEach(activePanel => {
        leave(activePanel).then(() => {
          activePanel.setAttribute("data-shown", false);

          panels.forEach(panel => {
            enter(panel).then(() => panel.setAttribute("data-shown", "true"));
          });
        });
      });
    } else {
      panels.forEach(panel => {
        enter(panel).then(() => panel.setAttribute("data-shown", "true"));
      });
    }
  }
}
