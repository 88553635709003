import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", this.submit.bind(this));
  }

  submit() {
    this.element.requestSubmit();
  }
}