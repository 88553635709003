
import Constants from "./constants";

const BG_CLASSES = {
  "notice": "bg-concert-800",
  "success": "bg-helper-800",
  "error": "bg-crimson-800",
  "alert": "bg-helper-800"
};

const BUTTON_CLASSES = {
  "notice": "hover:bg-concert-700 focus:bg-concert-700",
  "success": "hover:bg-helper-700 focus:bg-helper-700",
  "error": "hover:bg-crimson-700 focus:bg-crimson-700",
  "alert": "hover:bg-helper-700 focus:bg-helper-700"
};

function getTurboFlashContainer() {
  return document.getElementById("turbo_flash");
}

function addFlash(message, key = "alert") {
  const html = `
    <div
      data-controller="turbo-flash"
      data-turbo-flash-target="container"
      data-turbo-flash-autohide-value="true"
      data-modal-target="forceCloseOnAppear"
      data-transition-enter="transition transform ease-out duration-300"
      data-transition-enter-start="transform scale-95 opacity-0"
      data-transition-enter-end="transform scale-100 opacity-100"
      data-transition-leave="transition ease-in duration-300"
      data-transition-leave-start="transform scale-100 opacity-100"
      data-transition-leave-end="transform scale-95 opacity-0"
      data-turbo-cache="false"
      class="mb-4 backdrop-blur-[2px] pointer-events-auto"
    >
      <div class="mx-auto">
        <div class="px-2 pr-3 py-2 rounded-xl shadow-lg ${BG_CLASSES[key]}">
          <div class="flex items-center justify-between">
            <p class="flash-message ml-2 text-sm text-concert-50 md:w-full md:text-left">
              ${message}
            </p>
            <div class="">
              <button type="button" class="-mr-1 flex p-2 rounded-lg ${BUTTON_CLASSES[key]} mxu-focus mxu-active transition ease-mxu duration-150" data-action="click->turbo-flash#dismiss">
                <i class="size-5 text-concert-50">
                  ${Constants.X_OUTLINE_HTML}
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;

  const element = getTurboFlashContainer();

  if (!element) {
    console.error(`unable to add message '${message}' to flash as ${key}, container is missing.`);
    return;
  }

  const newElement = document.createElement("div");
  newElement.innerHTML = html;

  element.appendChild(newElement.firstElementChild);
}

export default {
  addFlash
};