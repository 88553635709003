
import {enter, leave} from "el-transition";
import * as Util from "../lib/util";

function show() {
  const state = new State();

  state.overlay.classList.toggle("hidden", false);

  enter(state.container).then(() => {
    state.root.setAttribute("data-shown", true);
  });

  focus();

  Util.disableScroll();
}

function hide(container, overlay) {
  if (overlay != document.querySelector("[data-nav--command-palette-target='overlay']")) {
    overlay = document.querySelector("[data-nav--command-palette-target='overlay']");
    container = document.querySelector("[data-nav--command-palette-target='container']");
  }

  const state = new State();

  leave(container);

  leave(overlay).then(() => {
    state.root.setAttribute("data-shown", false);
  });

  Util.enableScroll();
}

function focus() {
  const state = new State();

  if (state.input) {
    state.input.focus();
  }
}

class State {
  get root() {
    return document.querySelector("div[data-controller='nav--command-palette']"); 
  }
  get container() {
    return this.root?.querySelector("div[data-nav--command-palette-target='container']"); 
  }
  get overlay() {
    return this.root?.querySelector("div[data-nav--command-palette-target='overlay']"); 
  }
  get input() {
    return this.root?.querySelector("input#query"); 
  }
  get shown() {
    return this.root?.getAttribute("data-shown") == "true"; 
  }
}

export default {
  show,
  focus,
  hide,
  State
};